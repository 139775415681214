import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  LocationsSorted,
} from "@bluefin/components";
import { Grid, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Locations"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            className={"custom-padding"}
          >
            <Grid.Column width={16}>
              <LocationsSorted locations={fishermanBusiness.locations} />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            columns={2}
            verticalAlign={"middle"}
            textAlign={"center"}
            className={"loc-social-media"}
          >
            <Grid.Column width={8}>
              <div>
                <h2>New Brighton</h2>
                <a
                  href={
                    "https://www.facebook.com/Big-Louies-New-Brighton-140106106046123"
                  }
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <Icon name={"facebook"} size={"large"} color={"black"} />
                </a>
                <a
                  href={"https://www.instagram.com/biglouiesnewbrighton/"}
                  target={"_blank"}
                >
                  <Icon name={"instagram"} size={"large"} color={"black"} />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div>
                <h2>Chrystal</h2>
                <a
                  href={"https://www.facebook.com/biglouiescrystal"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <Icon name={"facebook"} size={"large"} color={"black"} />
                </a>
                <a
                  href={"https://www.instagram.com/biglouiescrystal/"}
                  target={"_blank"}
                >
                  <Icon name={"instagram"} size={"large"} color={"black"} />
                </a>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Locations" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
